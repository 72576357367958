<template>
  <div class="footerPanel">
    <coordinate-system
      class="coordinateSystem"
      size="mini"
      :options="userCSs"
      :value="cs"
      @change-cs="changeCS"
      v-if="!loading"
    ></coordinate-system>
    <div class="coordinates">
      <el-input
        v-model="coordinates[0]"
        class="coordinatesSelect"
        size="mini"
        readonly>
      </el-input>
      <el-input
        v-model="coordinates[1]"
        class="coordinatesSelect"
        size="mini"
        readonly>
      </el-input>
    </div>
  </div>
</template>

<script>
  import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

  export default {
    name: 'footerPanel',
    components: { CoordinateSystem },
    props: {
      coordinates: {
        type: Array,
        default: function () {
          return [0, 0]
        }
      },
      cs: {
        type: Number
      },
      userCSs: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data: function () {
      return {
        coordinateSystems: [],
        loading: false
      }
    },
    methods: {
      changeCS(newValue) {
        this.$emit('change-target-cs', newValue)
      }
    }
  }
</script>

<style scoped>
  .footerPanel {
    position: absolute;
    bottom: 6px;
    right: 10px;
    z-index: 99;
    background-color: white;
  }
  .coordinateSystem {
    display: inline-block;
    width: 162px;
  }
  .coordinates {
    display: inline-block;
  }
  .coordinatesSelect {
    width: 97px;
    display: inline-block;
  }
  .footerPanel /deep/ .el-input__inner {
    font-size: 12px;
  }
</style>
